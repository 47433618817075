export const useCharterHotelStore = defineStore('charter-hotel', {
  state: () => ({
    charterHotel: {},
    charterHotelSections: [],
    charterHotelWeather: [],
    charterHotelPath: '',
    charterHotelTab: 'info',
    charterHotelTabs: [],
    charterHotelTabVisited: {
      info: false,
      roomType: false,
      food: false,
      reviews: false,
      facilities: false,
      activities: false,
    },
    bannerCharterHotel: {},
    loading: false
  }),
  getters: {
    GET_CHARTER_HOTEL_ID(state) {
      return state.charterHotel.id || null;
    },

    GET_CHARTER_HOTEL (state) {
      return state.charterHotel
    },

    GET_CHARTER_HOTEL_SECTIONS (state) {
      return state.charterHotelSections
    },

    GET_CHARTER_HOTEL_WEATHER (state) {
      return state.charterHotelWeather
    },
  },
  actions: {
    SET_CHARTER_HOTEL (data) {
      this.charterHotel = data
    },

    SET_CHARTER_HOTEL_SECTIONS (data) {
      this.charterHotelSections = data
    },

    SET_CHARTER_HOTEL_PATH (data) {
      this.charterHotelPath = data
    },

    SET_CHARTER_HOTEL_TABS (data) {
      this.charterHotelTabs = data
    },

    SET_CHARTER_HOTEL_TAB (data) {
      this.charterHotelTab = data
    },

    SET_CHARTER_HOTEL_TAB_VISITED (data) {
      this.charterHotelTabVisited = data
    },

    SET_CHARTER_HOTEL_WEATHER (data) {
      this.charterHotelWeather = data
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCharterHotelStore, import.meta.hot))
}